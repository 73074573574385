<script>
import BaseForm from './components/BaseForm.vue'
export default {
  name: 'EditForm',
  props: {
    branchId: {type: [String, Number], default: null}
  },
  components: {
    BaseForm
  },
  data() {
    return {
      progressing: false,
      initializing: true,
      model: {
        active: true,
        address: null,
        fax: null,
        latitude: null,
        logo: null,
        longitude: null,
        name: null,
        organizationId: this.branchId || null,
        tel: null
      }
    }
  }
}
</script>

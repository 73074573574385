<template>
  <ValidationObserver v-slot="{handleSubmit}">
    <m-card :title="$t('shared.add')">
      <base-form v-model="model" />

      <template #actions>
        <page-footer @submit="handleSubmit(submit)" :loading="progressing" />
      </template>
    </m-card>
  </ValidationObserver>
</template>

<script>
import EditForm from './EditForm'
export default {
  name: 'Create',
  mixins: [EditForm],
  methods: {
    submit() {
      this.progressing = true
      return this.$api.workingSites
        .create(this.model)
        .then((res) => {
          this.model = res
          this.$showSuccess(this.$t('workingSites.workingSiteAdded'))
        })
        .catch((err) => {
          this.$showError(err)
        })
        .finally(() => {
          this.progressing = false
        })
    }
  }
}
</script>
